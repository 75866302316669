<!--
 * @Description: 商城导航
 * @Autor: zhangzhang
 * @Date: 2021-06-10 19:31:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-10 09:54:44
-->
<template>
  <div class="wrap">
    <el-alert type="warning" show-icon title="提示：当前设置更改后，需点击顶部右上角 → “保存发布”，保存后才可生效。"></el-alert>
    <br />
    <!-- 导航展示 -->
    <div style="padding-right: 360px">
      <phone-ctn>
        <div class="edit">
          <nav-bar v-bind="project.config.navigation" :disabled="true"></nav-bar>
          <img src="../../../assets/image/title.gif" alt="" />
        </div>
      </phone-ctn>
    </div>

    <!-- 导航配置 -->
    <config-ctn title="系统导航">
      <config-wrap>
        <div class="mb15 f13">导航</div>
        <div class="nav">
          <div class="mt10 mb15 f12 f-grey">系统：可配置 1 至 5 个导航</div>
          <div class="mt10 mb15 f12 f-red">注意：第1个导航不可改变、不可删除、不可配置跳转</div>
          <draggable v-model="navigation.list">
            <div class="nav-item" v-for="(item, index) in navigation.list" :key="item.id">
              <div v-if="navigation.list.length > 2 && index > 0" class="nav-delete" @click="navigation.list.splice(index, 1)">
                <i class="f12 icon icon-cha-"></i>
              </div>
              <config-item label="上传图标" class="flex f13">
                <div class="flex">
                  <Imgpond class="mr10" v-model="item.iconImg" :count="1" />
                  <Imgpond v-model="item.iconImgSel" :count="1" />
                </div>
              </config-item>
              <config-item label="默认图标" v-if="!item.iconImg" class="flex f13">
                <icon-select v-model="item.icon"></icon-select>
              </config-item>
              <config-item label="菜单名称">
                <el-input v-model="item.text" size="small" maxlength="4" show-word-limit></el-input>
              </config-item>
              <config-item label="跳转页面" v-if="index > 0">
                <jump-select v-model="item.jump"></jump-select>
              </config-item>
            </div>
          </draggable>
          <div v-if="navigation.list.length < 5" class="nav-add" @click="add">新增导航</div>
        </div>
      </config-wrap>
    </config-ctn>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import IconSelect from '@/components/IconSelect'
import { mapGetters } from 'vuex'
import http from '@/utils/cloud'

export default {
  components: {
    NavBar,
    IconSelect
  },
  data() {
    return {
      iconImg: '',
      iconImgSel: ''
    }
  },

  computed: {
    ...mapGetters(['project']),

    navigation() {
      return this.project.config.navigation
    }
  },

  methods: {
    async imgSuccess(base64) {
      let result = await this.$aliOSS(base64)
      if (result) {
        console.log(result.url)
        this.iconImg = result.url
      } else {
        this.$message.error('图片失败')
        this.iconImg = ''
      }

      // const res = await http.POST('/jw-admin', {
      //   module: 'file',
      //   operation: 'addFile',
      //   base64: base64,
      // });
      // if (res) {
      //   this.iconImg = res.fileID;
      // } else {
      //   this.$message.error('图片失败');
      //   this.iconImg = '';
      // }
    },
    async imgSelSuccess(base64) {
      const res = await http.POST('/jw-admin', {
        module: 'file',
        operation: 'addFile',
        base64: base64
      })
      if (res) {
        this.iconImg = res.fileID
      } else {
        this.$message.error('图片失败')
        this.iconImg = ''
      }
    },
    add() {
      this.navigation.list.push({ value: '' })
    }
  }
}
</script>

<style lang="scss" scoped>
.ctn {
  position: relative;
  width: 375px; /*no*/
  margin: 70px auto; /*no*/
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  border: 6rpx solid #3d7dff;

  .ctn-head {
    widows: 100%; /*no*/
    height: 64px; /*no*/
    // background-image: url('./phone-head.png');
    background-size: cover;
  }
}
.edit {
  display: flex;
  align-items: flex-end;
  width: 100%;
  min-height: 603px /*no*/;
  background: #f7f8fa;
  position: relative;
  img {
    position: absolute;
    left: 50%;
    top: 20%;
    width: 160px;
    margin-left: -80px;
  }
}
</style>
